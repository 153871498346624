
import { Options, Vue } from 'vue-class-component';
import AppTile from './AppTile.vue';
import getAppDefinitions, { AppDefinitions } from '@/shared/apps';
import api from '@/shared/api';
import session from "@/shared/session";

@Options({
    components: {
        AppTile,
    },
})
export default class AppOverview extends Vue {
    protected apps: AppDefinitions = {};
    protected error = '';
    protected installedApps: {app_name: string}[] = [];

    get appName() {
        let appname = this.$route.params.appname;
        if (appname instanceof Array) {
            appname = appname[0];
        }
        return appname;
    }
    get app() {
        return this.apps[this.appName];
    }

    async installApp() {
        const loggedIn = await session.checkSession();
        if (loggedIn) {
            // Create order first
            const voucherMatch = window.location.href.match(/(\?|&)voucher=([a-zA-Z0-9]+)/);
            const voucher = voucherMatch ? voucherMatch[2] : '';

            const orderData = await api.createOrder(this.appName as string, voucher );
            if (orderData.error) {
                this.error = orderData.error;
                return;
            }

            if (orderData.paymentRequired) {
                const payResponse = await api.molliePay({
                    orderId: orderData.orderId
                });
                console.log('pay result', payResponse);

                if (payResponse.url) {
                    window.location.href = payResponse.url;
                }
            } else {
                window.location.href = '#/installedapps/store/register-payment-callback?orderId=' + orderData.orderId;
            }
        } else {
            await this.$router.push({ name: 'public-apps-purchase', params: this.$route.params });
        }
    }

    async created() {
        this.apps = await getAppDefinitions();
        this.installedApps = await api.getInstalledApps();
    }
    isInstalled(appName: string) {
        console.log(appName);
        // Loop through installed apps and check if the app is installed
        return this.installedApps.some(app => app.app_name === appName);
    }
}
