import api from '@/shared/api';
import router from '@/router';
import { JwtHelper } from '@/shared/jwt-helper';

class Session {
    protected tokens: null | { access_token: string; refresh_token: string } = null;
    protected businessUnits: { id: string; name: string; parent: any }[] = [];
    protected currentBusinessUnit: string | null = window.localStorage.getItem('bu');
    protected roles: string[] = [];

    async setTokens(tokens: { access_token: string; refresh_token: string }) {
        this.tokens = tokens;

        const claims = new JwtHelper().decodeToken(tokens.access_token);
        this.roles = claims.roles;

        this.setRefreshTimer();

        window.localStorage.setItem('ta', tokens.access_token);
        window.localStorage.setItem('tr', tokens.refresh_token);

        const data = await api.getBusinessUnits();
        this.businessUnits.splice(0);

        let currentBusinessUnitFound = false;
        for (const bu of data) {
            if (!this.currentBusinessUnit) {
                this.currentBusinessUnit = bu.id;
            }
            if (this.currentBusinessUnit && this.currentBusinessUnit === bu.id) {
                currentBusinessUnitFound = true;
            }
            this.businessUnits.push(bu);
        }

        if (!currentBusinessUnitFound) {
            this.currentBusinessUnit = this.businessUnits[0].id;
        }
    }

    protected refreshTimer: any;
    protected timeout = 100 * 3600;
    setRefreshTimer() {
        if (this.refreshTimer) {
            clearTimeout(this.refreshTimer);
        }

        this.refreshTimer = setTimeout(() => {
            this.refreshTokens();
        }, this.timeout);
    }

    async refreshTokens() {
        if (this.tokens) {
            const data = await api.authRefresh(this.tokens.refresh_token);
            if (data) {
                await this.setTokens(Object.assign(this.tokens, data));
                return true;
            } else {
                router.push({ name: 'login' });
            }
        }
    }

    async checkSession() {
        // try {
        //     const data = await api.authRefresh(tr);
        //     if (data.error) {
        //         return false;
        //     } else {
        //         this.setTokens(Object.assign({refresh: tr}, data));
        //         return true;
        //     }
        // } catch (e) {
        //     return false;
        // }
        if (!this.tokens) {
            const tr = window.localStorage.getItem('tr');
            const ta = window.localStorage.getItem('ta');
            // const ti = window.localStorage.getItem('ti');
            console.log('check session', tr);
            // todo: check if access token is still valid
            // if (ta && tr && ti) {
            //     const data = await api.authRefresh(tr);
            //     if (data.error) {
            //         return false;
            //     } else {
            //         this.setTokens(Object.assign({
            //             refresh: tr,
            //             access: ta,
            //             id: ti
            //         }, data));
            //         return true;
            //     }
            // }
            if (tr) {
                const data = await api.authRefresh(tr);
                if (data) {
                    await this.setTokens(
                        Object.assign(
                            {
                                refresh_token: tr,
                                access_token: ta,
                            },
                            data,
                        ),
                    );
                    return true;
                }
            }

            return false;
        }
        return true;
    }

    async logout() {
        if (this.tokens && this.tokens.refresh_token) {
            await api.logout(this.tokens.refresh_token);
        }

        this.tokens = null;
        window.localStorage.removeItem('tr');
        window.localStorage.removeItem('ta');
        window.localStorage.removeItem('ti');
    }

    getAccessToken() {
        return this.tokens?.access_token;
    }

    getBusinessUnits() {
        return this.businessUnits;
    }

    setCurrentBusinessUnit(bu: string | null) {
        if (bu !== this.currentBusinessUnit) {
            this.currentBusinessUnit = bu;
            if (typeof bu === 'string') {
                window.localStorage.setItem('bu', bu);
            } else {
                window.localStorage.removeItem('bu');
            }
            // TODO: Better refresh
            router.push({ name: 'apps-store' });
        }
    }

    getCurrentBusinessUnit() {
        return this.currentBusinessUnit;
    }

    getRoles() {
        return this.roles;
    }

    hasRole(role: string) {
        return this.roles.indexOf(role) !== -1;
    }
}

const session = new Session();

export default session;
