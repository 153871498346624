
import { Options, Vue } from 'vue-class-component';
import LegacyModal from "@/components/common/LegacyModal.vue";
import api from "@/shared/api";

type Env = {
    name: string;
    key: string;
    redirect_uri: string;
};

@Options({
    components: {Modal: LegacyModal}
})
export default class AppStorePurchaseRegisterPaymentCallback extends Vue {
    protected ready = false;
    protected error = '';
    protected name = '';
    protected email = '';
    protected businessUnitName = '';
    protected newUser: boolean = true;
    protected passwordToken = '';
    protected passwordSaved = false;
    protected password = '';
    protected confirmPassword = '';
    protected orderId = '';
    protected signupCompleted = false;

    async created() {
        this.orderId = this.$route.query.orderId as string;
        await this.waitForPaymentComplete();
    }

    async waitForPaymentComplete() {
        const status = await api.getOrderPaymentStatus(this.orderId);
        if (status.paymentStatus === 'paid') {
            const response = await api.saveOrderPaymentComplete(this.orderId);

            this.$router.push({ name: 'apps-installed-overview' });
        } else {
            setTimeout(() => {
                this.waitForPaymentComplete();
            }, 1000);
        }
    }
}
