
import { Options, Vue } from 'vue-class-component';
import Modal from "@/components/common/Modal.vue";
import api from "@/shared/api";
import session from "@/shared/session";

type Env = {
    name: string;
    key: string;
    redirect_uri: string;
};

@Options({
    components: {Modal}
})
export default class PurchaseLoginOrRegister extends Vue {
    protected ready = false;
    protected environments: Env[] = [];
    protected selectedEnv: Env|null = null;
    protected error = '';
    protected email = '';
    protected password = '';

    async created() {
        this.environments = await api.getSprinklrEnvironments();
        this.ready = true;

        session.checkSession().then(
            (loggedIn) => {
                if (loggedIn) {
                    this.$router.push('/installedapps');
                }
            },
        );
    }

    async openSprinklr(env: Env) {
        // Create order first
        const voucherMatch = window.location.href.match(/(\?|&)voucher=([a-zA-Z0-9]+)/);
        const voucher = voucherMatch ? voucherMatch[2] : '';

        const orderData = await api.createOrder(this.$route.params.appname as string, voucher );
        if (orderData.error) {
            this.error = orderData.error;
            return;
        }

        const orderId = orderData.orderId;
        const appStoreEnv = window.location.port !== '443' && window.location.port !== '' ? 'development' : 'prod';
        const state = appStoreEnv + '-order-' + orderId;
        const redirect_uri = env.redirect_uri;
        const client_id = env.key;

        // TODO: Uncomment:
        window.location.href = 'https://api2.sprinklr.com/' + env.name + '/oauth/authorize?client_id=' + client_id + '&response_type=code&redirect_uri=' + redirect_uri + '&state=' + state;

        // TODO: Remove this:
        // window.location.href = redirect_uri + '?code=1234&state=' + state + '&env=' + env.name;
    }

    async login() {
        this.error = '';
        try {
            const data = await api.authLogin(this.email, this.password);
            console.log('login result', data);
            if (data.error) {
                this.error = data.error;
            } else {
                await session.setTokens(data);
                await this.$router.push({ name: 'app-details', params: { appname: this.$route.params.appname } });
            }
        } catch (error) {
            console.error(error);
            if (String(error).includes('401')) {
                this.error = "Invalid Username or Password 😔"
            }
            else {
                this.error = String(error).toString()
            }
            this.email = '';
            this.password = '';
        }
    }
}
